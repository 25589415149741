import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5000/api/';

class RepaymentsService {
  async getRepayments(user) {
    const { data } = await Axios.post(`${API_URL}repayments`, user);
    return data;
  }
  async getRepayment(user, id) {
    let req = {
      ...user,
      repaymentId: id
    }
    const { data } = await Axios.post(`${API_URL}repayments`, req);
    return data;
  }
}

export default new RepaymentsService();